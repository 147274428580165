const initialState = {
    allPosts: []
}

const PostReducer = (state=initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_POSTS':
            return { ...state, allPosts: action.data };
        default:
            return state;
    }

}

export default PostReducer;